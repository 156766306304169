import React, { useContext } from "react"
import { PageContext, PageData } from "../PageLayout"
import styled from "styled-components/macro"
import color from "../scripts/colors"
import getCldnyUrl from "../scripts/getCldnyUrl"

type RepImage = {
  imgSrc: string
}

const ContactRepWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: 50px;
`

const ContactRepImage = styled.div`
  height: 150px;
  width: 115px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-right: 20px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.75);
  background-image: ${(props: RepImage) =>
    props.imgSrc ? `url(${props.imgSrc})` : null};
`

const ContactRepInfo = styled.div`
  h2 {
    font-weight: 300;
    font-size: 35px;
    margin: 0 0 5px;
    color: ${color("main", "lt")};
  }
  h3 {
    font-weight: 300;
    font-size: 15px;
    font-style: italic;
    margin: 0;
    text-transform: uppercase;
  }
`

const ContactRep = () => {
  const pageData: PageData = useContext(PageContext)
  const { contactRep } = pageData
  const imgUrl = getCldnyUrl(
    contactRep.contactRepImageImage,
    "h_300,q_auto,f_auto,c_thumb,g_face"
  )

  return (
    <ContactRepWrap>
      <ContactRepImage imgSrc={imgUrl} />
      <ContactRepInfo>
        <h2>{contactRep.contactRepImageName}</h2>
        <h3>{contactRep.contactRepImagePosition}</h3>
      </ContactRepInfo>
    </ContactRepWrap>
  )
}

export default ContactRep
