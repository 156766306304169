import React, { useContext } from "react"
import ContactForm from "../components/ContactForm"
import { PageMargin, PageTitle } from "../GlobalStyles"
import styled from "styled-components/macro"
import PageLayout, { PageContext, PageData } from "../PageLayout"
import color from "../scripts/colors"
import ContactRep from "../components/ContactRep"
import Address from "../components/Address"

const PhoneStyles = styled.p`
  font-size: 35px;
  color: ${color("main")};
  @media screen and (min-width: 550px) {
    font-size: 50px;
  }
`

const Phone = () => {
  const pageData: PageData = useContext(PageContext)
  const { facilityPhone } = pageData

  return <PhoneStyles>{facilityPhone}</PhoneStyles>
}

const contact = () => {
  return (
    <PageLayout>
      <PageMargin>
        <PageTitle>Contact</PageTitle>
        <ContactRep />
        <Phone />
        <ContactForm />
        <Address />
      </PageMargin>
    </PageLayout>
  )
}

export default contact
