import React, { useState, useContext } from "react"
import { useForm } from "react-hook-form"
import { PageContext, PageData } from "../PageLayout"
import axios from "axios"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { Button } from "../GlobalStyles"
import styled, { keyframes } from "styled-components/macro"
import color from "../scripts/colors"
import "fontsource-open-sans/600.css"
import { FaExclamationCircle, FaSpinner, FaCheck } from "react-icons/fa"

type IFormInputs = {
  firstName: string
  lastName: string
  email: string
  phone: string
  message: string
}

type SubmitStateTyp = {
  submitState: string
}

const schema = yup.object().shape({
  firstName: yup.string().required(`First Name is required`),
  lastName: yup.string().required(`Last Name is required`),
  email: yup.string().email(`Not an email`).required(`Email is required`),
  message: yup.string().required(`Message is required`),
})

const Form = styled.form`
  max-width: 600px;
  margin-bottom: 75px;
`

const InputWrap = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: block;
  label,
  p {
    width: 100%;
  }
  label {
    color: ${color("main", "dk")};
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
  }
  input,
  textarea {
    padding: 10px;
    border: 1px solid ${color("main", "lt")};
    width: calc(100% - 22px);
    &,
    &:focus,
    &:active {
      outline: none;
    }
    &[type="submit"],
    &.InputButton {
      display: inline-flex;
      border: none;
      width: auto;
      padding: 10px calc(14px - 0.2rem) 10px 14px;
    }
  }
`

const InputGroup = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media screen and (min-width: 550px) {
    flex-direction: row;
  }
  & > div {
    width: 100%;
    @media screen and (min-width: 550px) {
      width: 48%;
    }
  }
`

const ReqStyle = styled.span`
  color: ${color("error")};
`

const Req = () => {
  return <ReqStyle>*</ReqStyle>
}

const ErrorMessage = styled.p`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${color("error")};
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 5px 0 0;
  svg {
    margin-right: 5px;
  }
`

const SubmitButtonAnimation = keyframes`
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
`

const SubmitButton = styled(Button)`
  display: inline-flex;
  margin-right: 20px;
  cursor: ${(props: SubmitStateTyp) =>
    props.submitState === "none"
      ? "pointer"
      : (props.submitState === "sending" || props.submitState === "sent") &&
        "default"};
  &,
  &:hover {
    background-color: ${(props: SubmitStateTyp) =>
      props.submitState === "sending"
        ? color("main", "lt")
        : props.submitState === "sent" && color("success")};
  }
  svg {
    margin-left: 10px;
  }
  .spinner {
    animation-name: ${SubmitButtonAnimation};
    animation-duration: 1500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
`

const ContactForm = () => {
  const pageData: PageData = useContext(PageContext)
  const { facilityEmail } = pageData

  const { register, handleSubmit, errors } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
  })

  const [submitState, setSubmitState] = useState("none")

  const onSubmit = async (data: IFormInputs) => {
    setSubmitState("sending")

    await axios
      .post("/.netlify/functions/postmarkSend", {
        ...data,
        facilityEmail,
      })
      .then(res => {
        setSubmitState("sent")
      })
      .catch(error => {
        console.log(`CLIENT SIDE ERROR: ${error}`)
      })
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <InputGroup>
        <InputWrap>
          <label>
            First Name <Req />
          </label>
          <input type="text" name="firstName" ref={register} />
          <ErrorMessage>
            {errors.firstName && <FaExclamationCircle />}
            {errors.firstName?.message}
          </ErrorMessage>
        </InputWrap>

        <InputWrap>
          <label>
            Last Name <Req />
          </label>
          <input type="text" name="lastName" ref={register} />
          <ErrorMessage>
            {errors.lastName && <FaExclamationCircle />}
            {errors.lastName?.message}
          </ErrorMessage>
        </InputWrap>
      </InputGroup>

      <InputGroup>
        <InputWrap>
          <label>
            Email <Req />
          </label>
          <input type="text" name="email" ref={register} />
          <ErrorMessage>
            {errors.email && <FaExclamationCircle />}
            {errors.email?.message}
          </ErrorMessage>
        </InputWrap>

        <InputWrap>
          <label>Phone</label>
          <input
            type="text"
            placeholder="### ### ####"
            name="phone"
            ref={register}
          />
        </InputWrap>
      </InputGroup>

      <InputWrap>
        <label>
          Message <Req />
        </label>
        <textarea name="message" ref={register} />
        <ErrorMessage>
          {errors.message && <FaExclamationCircle />}
          {errors.message?.message}
        </ErrorMessage>
      </InputWrap>

      <InputWrap>
        <SubmitButton type="submit" submitState={submitState}>
          {submitState === "none" && "Submit"}
          {submitState === "sending" && "Sending"}
          {submitState === "sent" && "Success"}
          {submitState === "sending" && <FaSpinner className="spinner" />}
          {submitState === "sent" && <FaCheck className="check" />}
        </SubmitButton>

        <Button
          as="input"
          type="reset"
          onClick={() => setSubmitState("none")}
          className="InputButton"
        />
      </InputWrap>
    </Form>
  )
}

export default ContactForm
