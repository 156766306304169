import React, { useContext } from "react"
import styled from "styled-components/macro"
import { Button, SectionTitle } from "../GlobalStyles"
import { PageContext, PageData } from "../PageLayout"

const AddressStyle = styled.div``

const AddressLine = styled.p`
  font-size: 25px;
  margin: 0 0 5px;
`

const GoogleMapsButton = styled(Button)`
  display: inline-block;
  width: auto;
  margin-top: 20px;
`

const Address = () => {
  const pageData: PageData = useContext(PageContext)
  const { address } = pageData
  const { facilityAddress, facilityCity, facilityState, facilityZip } = address

  return (
    <AddressStyle>
      <SectionTitle left>Address</SectionTitle>
      <AddressLine>{facilityAddress}</AddressLine>
      <AddressLine>{`${facilityCity}, ${facilityState} ${facilityZip}`}</AddressLine>
      <GoogleMapsButton
        as="a"
        rel="nofollow"
        target="_blank"
        href={`https://www.google.com/maps/place/${facilityAddress}+${facilityCity},+${facilityState}+${facilityZip}`}
      >
        View on Google Maps
      </GoogleMapsButton>
    </AddressStyle>
  )
}

export default Address
